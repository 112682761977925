<template>
	<div>
		<ValidationObserver ref="updateForm">
			<b-row>
				<b-col rows="12" md="6" lg="6">
					<ValidationProvider name="semester" rules="required" v-slot="{valid, errors}">
						<b-form-group :label="$t('semester')">
							<semesters-selectbox
								:validate-error="errors[0]"
                                :setActive="true"
								v-model="defineForm.semester_id">
							</semesters-selectbox>
						</b-form-group>
					</ValidationProvider>
				</b-col>
				<div class="col-12 col-sm-6 col-md-6 col-lg-6">
					<ValidationProvider name="programlevel" rules="required" v-slot="{valid, errors}">
						<b-form-group :label="$t('program_level')">
							<program-level-selectbox 
								v-model="defineForm.type"
								:noOL="true" 
								:validate-error="errors[0]" 
								:setActive="true"
							/>
						</b-form-group>
					</ValidationProvider>
				</div>
				<b-col rows="12" md="6" lg="6">
					<ValidationProvider name="file" rules="required" v-slot="{valid, errors}">
						<b-form-group :label="$t('excel_file')">
							<div class="d-flex custom-file-upload">
								<b-form-file
									v-model="defineForm.file"
									:state="errors[0] ? false : (valid ? true : null)"
									:placeholder="$t('select_file')"
									:drop-placeholder="$t('drop_file')"
									ref="fileInput"
									multiple></b-form-file>
								<b-button variant="outline-secondary" @click="$refs.fileInput.$el.childNodes[0].click();">{{ $t('browse') }}</b-button>
							</div>
							<div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
						</b-form-group>
					</ValidationProvider>
				</b-col>
				<b-col rows="12" md="12" lg="12">
					<b-form-group :label="$t('excel_format')">
						<div class="alert alert-info">
							{{ $t('exam_schedule_format') }}
						</div>
					</b-form-group>
				</b-col>
				<b-col rows="12" md="12" lg="12">
					<div class="d-flex justify-content-center mb-5">
						<b-button variant="primary" @click="upload">
							{{ $t('import').toUpper() }}
						</b-button>
					</div>
				</b-col>
			</b-row>
		</ValidationObserver>
	</div>
</template>
<script>
	// Component
	import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
	import ProgramLevelSelectbox from '@/components/interactive-fields/ProgramLevelSelectboxLYL';
	import {ValidationObserver, ValidationProvider} from "vee-validate";

	// Services
	import ExamScheduleService from "@/services/ExamScheduleService";

	export default {
		components: {
			SemestersSelectbox,
			ProgramLevelSelectbox,
			ValidationProvider,
			ValidationObserver,
		},
		data() {
			return {
				defineForm: {
					semester_id: null,
					file: null
				}
			}
		},
		methods: {
			async upload() {
				const isValid = await this.$refs.updateForm.validate();
				if (isValid) {
					let formData = new FormData();
					formData.append('file', this.defineForm.file[0])
					
					if(this.defineForm.type == 'L') {
						formData.append('type', 'undergraduate')
					}
					else {
						formData.append('type', 'graduate')
					}

					ExamScheduleService.fileImport(this.defineForm.semester_id, formData)
					                   .then(response => {
						                   this.$toast.success(this.$t('api.' + response.data.message));
						                   this.$router.push('/job/logs/detail/' + response.data.data.log_id)
					                   })
					                   .catch(e => {
						                   if (e.status == 406) {
							                   if (e.data.message) {
								                   this.$refs.updateForm.errors.moduleId.push(this.$t('api.' + e.data.message));
								                   this.$toast.error(this.$t('api.' + e.data.message));
							                   }
						                   }
						                   else {
							                   if (e.data.errors.file) {
								                   this.$refs.updateForm.errors.file.push(e.data.errors.file[0]);
							                   }
						                   }
					                   })
				}
			}
		}
	}
</script>
